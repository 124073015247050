import React from 'react';

import Row from '../Container/row';
import Col from '../Container/column';
import Placeholder from '../Placeholder';

import '../Subpage/style.css';
import { BorderRadius } from '../../utils/variables';

import loadComponents from '../Loadable';

const Blog = loadComponents('blog-items');

export default function BlogLayout(props) {
  const { allWpPost } = props;
  return (
    <Row justify="space-between">
      <Col className="pageContent blog">
        <Blog data={allWpPost} fallback={<BlogLoader />} />
      </Col>
    </Row>
  );
}

const BlogLoader = () => (
  <Row justify="space-between" style={{ marginBottom: `3.75rem` }}>
    <Col lg={{ width: '20%' }}>
      <Placeholder width="100%" colour="#222" style={{ paddingTop: `100%` }} />
    </Col>
    <Col lg={{ width: '77%' }}>
      <Placeholder
        width="300px"
        height="36px"
        colour="#222"
        style={{ marginBottom: `2.8125rem` }}
      />
      <Placeholder
        width="240px"
        height="18px"
        colour="#222"
        style={{ marginBottom: `0.625rem` }}
      />
      <Placeholder
        width="240px"
        height="18px"
        colour="#222"
        style={{ marginBottom: `1.875rem` }}
      />
      {[
        ...Array(4)
          .fill()
          .map((_, i) => {
            const key = `blogPlaceholder${i}`;
            return (
              <Placeholder
                key={key}
                width="100%"
                height="18px"
                colour="#222"
                style={{ marginBottom: `0.625rem` }}
              />
            );
          }),
      ]}
      <Placeholder
        width="167px"
        height="57px"
        colour="#222"
        style={{
          marginTop: `1.875rem`,
          borderRadius: BorderRadius,
        }}
      />
    </Col>
  </Row>
);
